:root {
    /* INPUT */
    --search-input-stroke: #C6CDD0;
    --search-input-stroke-active: #00374F;
    --search-input-color: #606F76;
    --search-slot-rolling-effect-color: #a9a9a9;
    --search-input-icon-x: #606F76;
    --search-title-bottom-margin: 18px;
    --margin-input-icon-magnify: 12px;

    /* ICON */
    --search-icon-arrow: url('../../search/image/verajohn-jp/arrow.svg');
    --search-icon-magnify: url('../../search/image/verajohn-jp/magnify.svg');
    --search-icon-close: url('../../search/image/verajohn-jp/close.svg');
    --search-icon-clear: url('../../search/image/verajohn-jp/close.svg');

    /* BACKGROND */
    --search-bg-placeholder: #EFF3F5;
    --search-background-radius: 8px;
    --search-background-mobile-radius: 8px;
    --search-overlay-backdrop-color: #000;

    /* STROKE */
    --search-stroke: #399fe3;
    --search-stroke-hover: #56b1ee;
    --search-stroke-active: #56b1ee;

    /* GAME GRIDS */
    --search-results-grid: repeat(2, 1fr);
    --search-results-sm-grid: repeat(3, 1fr);
    --search-results-md-grid: repeat(4, 1fr);
    --search-results-lg-grid: repeat(5, 1fr);
    --search-carousel-width: 164px;
    --search-game-tile-height: 220px;

    /* FILTER DROPDOWN */
    --search-filter-bg-color: #006997;
    --search-filter-stroke: #006997;
    --search-filter-text: #fff;
    --search-filter-icon: #fff;
    --search-tag-bg-color: #00A1E5;
    --search-tag-stroke: #0076AA;
    --search-input-border-width: 1px;
}